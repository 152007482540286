import React from 'react';

const About = () => {
  return (
    <section className="about">
      <p>Holi</p>
    </section>
  );
};

export default About;
